import Loadable from "../layouts/full-layout/loadable/Loadable";
import { lazy } from "react";

export const FullLayout = Loadable(
  lazy(() => import("../layouts/full-layout/FullLayout"))
);
export const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank-layout/BlankLayout"))
);

export const Error = Loadable(
  lazy(() => import("../views/authentication/Error"))
);
// const Login = Loadable(lazy(() => import("../views/authentication/Login")));
export const Hompage = Loadable(
  lazy(() => import("../Pages/Dashboard/Dashboard"))
);
export const LOGINS_LOGS = Loadable(
  lazy(() => import("../Pages/Login_Logs/LoginLogs"))
);
export const CustomLogin = Loadable(lazy(() => import("../Pages/Login/Login")));
export const SIGNIN = Loadable(lazy(() => import("../Pages/Singup/Signin")));
export const SubcriptionPage = Loadable(
  lazy(() => import("../Pages/SubcriptionalManagement/Subscription"))
);
export const OrganisationPage = Loadable(
  lazy(() => import("../Pages/OrganisationalManagement/Organisational"))
);
export const FLOW_ADD = Loadable(
  lazy(() => import("../Pages/Flow_Add/Flow_Add"))
);
export const CreateOrganisationPage = Loadable(
  lazy(() => import("../Pages/CreateOrg/CreteOrg"))
);
export const VIEW_WORKFLOW = Loadable(
  lazy(() => import("../Pages/DND/WF_Mang/View_Workflow"))
);
export const REPORT_MANAGEMENT = Loadable(
  lazy(() => import("../Pages/Report_Management/Report_Mamangement"))
);
export const SENDGRID = Loadable(
  lazy(() => import("../Pages/Sendgrid/Sendgrid"))
);
export const SubPage = Loadable(
  lazy(() => import("../Pages/Subscription/Subscription"))
);
export const TRANSACTION_MONITORING = Loadable(
  lazy(() => import("../Pages/Transaction_Monitoring/Transaction_Monitoring"))
);
export const TRANSACTION_MONITORING_MANAGEMENT = Loadable(
  lazy(() =>
    import("../Pages/Transaction_Monitoring/Transaction_Monitoring_Management")
  )
);
export const UserPage = Loadable(
  lazy(() => import("../Pages/User/UserManagement"))
);
export const ForgetPasswordPage = Loadable(
  lazy(() => import("../Pages/Forget/ForgetPassword"))
);
export const PAYMENT = Loadable(
  lazy(() => import("../Pages/Stripe/PaymentPage"))
);
export const ResetPassword = Loadable(
  lazy(() => import("../Pages/Forget/ResetPassword"))
);
export const TeamMebmerVerified = Loadable(
  lazy(() => import("../Pages/Team-Verified/TeamMeberForm"))
);
export const SuccessVerified = Loadable(
  lazy(() => import("../Pages/Team-Verified/SuccesVerified"))
);
export const REPORT_PAGE = Loadable(
  lazy(() => import("../Pages/Verificatoin_Detail/Report/ReportPage"))
);
export const SUBPLANE = Loadable(
  lazy(() => import("../Pages/SubPlan/SubPlan"))
);
export const APISETTING = Loadable(
  lazy(() => import("../Pages/ApiSetting/ApiSetting"))
);
export const DATARETENTION = Loadable(
  lazy(() => import("../Pages/DataRetention/DataRetention"))
);
export const PLANS = Loadable(
  lazy(() => import("../Pages/Plan&Payment/Plans"))
);
export const PLANPAYMENT = Loadable(
  lazy(() => import("../Pages/Plan&Payment/Payment"))
);
export const VERIFICATION = Loadable(
  lazy(() => import("../Pages/Verificatoin_Detail/Verification"))
);
export const ADD_EQUITY_INDIVIDUAL = Loadable(
  lazy(() => import("../Pages/Bussiness/Add_Individual"))
);
export const TwoFa = Loadable(lazy(() => import("../Pages/2Fa/TwoFa")));
export const Invoice = Loadable(lazy(() => import("../Pages/Invoice/Invoice")));
export const CREADIT_INVOICE = Loadable(
  lazy(() => import("../Pages/Invoice/Credit_Invoice"))
);
export const PRICINGCARDS = Loadable(
  lazy(() => import("../Pages/LandingPage/Pricing"))
);
export const SHOWCARD = Loadable(
  lazy(() => import("../Pages/LandingPage/ShowCard"))
);
export const REGISTEREDPAYMENT = Loadable(
  lazy(() => import("../Pages/LandingPage/Payment"))
);
export const NOTIFICATION_PAGE = Loadable(
  lazy(() => import("../Pages/Notification/Notification"))
);
export const ROLEMANAGEMENT = Loadable(
  lazy(() => import("../Pages/Role_Page/RoleMang"))
);
export const ADDROLE = Loadable(
  lazy(() => import("../Pages/Role_Page/CreateRolePage"))
);
export const EDITROLE = Loadable(
  lazy(() => import("../Pages/Role_Page/EditRole"))
);
export const VIEWROLE = Loadable(
  lazy(() => import("../Pages/Role_Page/ViewRoles"))
);
export const SWAGGER = Loadable(lazy(() => import("../Pages/Swagger/Swagger")));

export const FUISCREEN = Loadable(lazy(() => import("../Pages/FUI/FUI")));
export const FIU_LOGS = Loadable(lazy(() => import("../Pages/FUI/FIU_Logs")));
export const DRAG_AND_DROP = Loadable(lazy(() => import("../Pages/DND/DND")));
export const WORK_FLOW_MANAGEMENT = Loadable(
  lazy(() => import("../Pages/DND/WF_Mang/WorkFlow_Mang"))
);
export const Work_Flow_Udate = Loadable(
  lazy(() => import("../Pages/DND/WF_Mang/Update_WorkFlow"))
);
export const CREADIT_PAYMENT = Loadable(
  lazy(() => import("../Pages/Stripe/Creadit_Payment"))
);
export const ADD_USER = Loadable(
  lazy(() => import("../Pages/Add_User/Add_User"))
);
export const ADD_USER_LINKED = Loadable(
  lazy(() => import("../Pages/Bussiness/Add_Bussiness_Equity"))
);
export const EDD_SCREEN = Loadable(
  lazy(() => import("../Pages/Risk_Matrix/Individual/EDD"))
);
export const CDD_SCREEN = Loadable(
  lazy(() => import("../Pages/Risk_Matrix/Individual/CDD"))
);
export const Risk_Matrix_MAN = Loadable(
  lazy(() => import("../Pages/Risk_Matrix/Individual/Risk_Matrix"))
);
export const Risk_Matrix_ENTITY = Loadable(
  lazy(() => import("../Pages/Risk_Matrix/Entity/Risk_Matrix"))
);

export const BUSSINESS = Loadable(
  lazy(() => import("../Pages/Bussiness/Bussiness"))
);
export const DEV_OPTION = Loadable(
  lazy(() => import("../Pages/DeveloperOption/Dev_Option"))
);
export const ADD_BUSSINESS = Loadable(
  lazy(() => import("../Pages/Bussiness/Add_Bussiness"))
);
export const UPDATE_BUSSINESS = Loadable(
  lazy(() => import("../Pages/Bussiness/Update_Bussiness"))
);
export const BUSSINESS_DETAILS = Loadable(
  lazy(() => import("../Pages/Bussiness/Bussiness_Detail"))
);
export const EMAIL_TEMPLATE = Loadable(
  lazy(() => import("../Pages/Email_Template/Email_Template"))
);
export const ADD_TEMPLATE = Loadable(
  lazy(() => import("../Pages/Email_Template/Add_Email_Template"))
);
export const SCHEDULE = Loadable(
  lazy(() => import("../Pages/Schedule/Schedule"))
);
export const ADD_SCHEDULE = Loadable(
  lazy(() => import("../Pages/Schedule/ADD_SCHEDULE/ADD_SCHEDULE"))
);
export const UPDATE_SCHEDULE = Loadable(
  lazy(() => import("../Pages/Schedule/UPDATE_SCHEDULE/UPDATE_SCHEDULE"))
);
export const WEB_HOOK = Loadable(
  lazy(() => import("../Pages/Webhook/Weebhooks"))
);
export const WEB_HOOK_LOG = Loadable(
  lazy(() => import("../Pages/Webhook/Webhook_Log/Webhook_Log"))
);
export const ADD_WEBHOOK_MODAL = Loadable(
  lazy(() => import("../Pages/Webhook/WebhookModal"))
);
export const WebhookModal_Update_View = Loadable(
  lazy(() => import("../Pages/Webhook/WebhookModal"))
);
export const CREADIT_LOGS = Loadable(
  lazy(() => import("../Pages/Creadit_Logs/Creadit_logs"))
);
