import ReactCountryFlag from "react-country-flag";
import {
  findFlagUrlByNationality,
  findFlagUrlByIso2Code,
} from "country-flags-svg";
import { countries } from "country-flags-svg";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export const AccessDetail = [
  {
    name: "Dashboard",
    route: "/dashboard",
    api: "/dashboard",
  },

  {
    name: "Individual",
    route: "/individual-management",
    api: "/user/retrive/all",
  },

  {
    name: "Team",
    route: "/team-management",
    api: "/team/retrive/all",
  },
  {
    name: "Organization",
    route: "/organization",
    api: "/2FADetails",
  },

  {
    name: "Subscription",
    route: "/subscription",
    api: "/payHistory",
  },
  {
    name: "Data retention",
    route: "/data-retention",
    api: "/update/retentionPeriod",
  },
  {
    name: "Api Settings",
    route: "/api-setting",
    api: "/URL",
  },

  {
    name: "Roles management",
    route: "/roles-management",
    api: "",
  },
  {
    name: "Api Documentation",
    route: "/API-documentation",
    api: "",
  },
  {
    name: "FIU",
    route: "/fiu",
    api: "",
  },
  {
    name: "Work Flow",
    route: "/workflow-management",
    api: "",
  },
  {
    name: "Entity",
    route: "/entity-management",
    api: "",
  },
  {
    name: "Risk Matrix",
    route: "/risk-matrix",
    api: "",
  },
  {
    name: "Summary/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Address/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "CDD/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "EDD/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Document/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Event/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Note/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Timeline/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Summary/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Address/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "CDD/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "EDD/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Document/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Event/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Note/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "Timeline/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "ShowScore/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "ShowScore/Entity",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "FIU Logs",
    route: "/fiu-logs",
    api: "",
  },
  {
    name: "Webhook Log",
    route: "/webhooks-management",
    api: "",
  },
  {
    name: "Credit Log",
    route: "/creadit-logs",
    api: "",
  },
  {
    name: "Login History",
    route: "/login-logs",
    api: "",
  },
  {
    name: "Email Template",
    route: "/email-template-management",
    api: "",
  },
  {
    name: "Schedule",
    route: "/schedule",
    api: "",
  },
  {
    name: "Notification",
    route: "/notifications",
    api: "",
  },
  {
    name: "CustomForm/Individual",
    route: "/individual-verification-detail",
    api: "",
  },
  {
    name: "CustomForm/Entity",
    route: "/entity-details",
    api: "",
  },
  {
    name: "Sendgrid",
    route: "/custom/mail/retrieve",
    api: "",
  },
];

const FlowName = {
  "Verify Selfi with Document": "Verify Selfie with Document",
};

export const ChangeName = (name) => {
  let newName = FlowName[name];
  if (newName) {
    return newName;
  } else {
    return name;
  }
};
export const DemoData = [
  {
    route: "/individual-management",
    tabName: "Individual",
    subs: [
      {
        api: "/user/retrive/all",
        name: "View",
        route: "/individual-management",
      },
      {
        api: "/user/retrive/all",
        name: "Edit",
        route: "/individual-management",
      },
      {
        api: "/user/retrive/all",
        name: "Delete",
        route: "/individual-management",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "Summary/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Edit",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "Address/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Edit",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Delete",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "CDD/Individual",
    subs: [
      {
        api: "",
        name: "Edit",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "EDD/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Edit",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "Document/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Edit",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Delete",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "Event/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "Note/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Edit",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Delete",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "Timeline/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/individual-verification-detail",
    tabName: "ShowScore/Individual",
    subs: [
      {
        api: "",
        name: "View",
        route: "/individual-verification-detail",
      },
      {
        api: "",
        name: "Edit",
        route: "/individual-verification-detail",
      },
    ],
  },
  {
    route: "/dashboard",
    tabName: "Dashboard",
    subs: [
      {
        api: "/dashboard",
        name: "View",
        route: "/dashboard",
      },
    ],
  },
];
// export function GET_COUNTRY_FLAG({ code = "US" }) {
//   return (
//     <ReactCountryFlag
//       countryCode={code}
//       style={{
//         fontSize: "2em",
//         lineHeight: "2em",
//       }}
//     />
//   );
// }
export function GET_COUNTRY_FLAG({ code = "US" }) {
  // const flagUrl = findFlagUrlByIso2Code(code);

  return (
    // flagUrl && <img src={flagUrl} style={{ height: "30px", width: "30px" }} />
    <span
      className={`fi fi-${code}`}
      style={{ width: "30px", height: "30px" }}
    ></span>
  );
}
export function HANDLE_NAVIGATE(LINK) {
  window.open(LINK, "_blank");
}
export function ReplacePhrase(originalString, replacement) {
  const regex = /Name/gi; // Create a regex to find all occurrences of "Name" or "name", case-insensitive
  return originalString.replace(regex, replacement); // Replace "Name" or "name" with the given string
}

export function ReName(input) {
  if (input == null || input === "") {
    return "";
  }
  return input.replace(/ /g, "_").toLowerCase();
}

const CheckRename = {
  peps: "PEP Scan",
  default: "Watchlists",
  dpeps: "DPEP Scan",
};
export function ReNameRenderObject(key) {
  let value = CheckRename[key];
  return value ? value : key;
}

export const IsEmptyObject = (obj) =>
  obj && typeof obj === "object" && Object.keys(obj).length !== 0;
export const NOTHING = () => {
  return (
    <div
      style={{
        width: "100%",
        margin: "3%",
        fontSize: "18px",
        fontWeight: "500",
        display: "flex",
        justifyContent: "center",
      }}
    >
      Cleared
    </div>
  );
};

const RENAME = {
  "Work Flow": "Workflow",
};

export function RENAME_SIDEBAR_OPTION(name) {
  if (RENAME[name]) return RENAME[name];
  else return name;
}

export function IsObject_Empty(obj) {
  if (obj === "clear") return false;
  return obj?.name ? true : false;

  // Returns true if object has no keys
}
