import { IconButton, Tooltip } from "@mui/material";

export const ToolTipWrapper = ({ Component, handleFunc, PlaceHolder, Pd }) => (
  <Tooltip
    title={PlaceHolder}
    style={{ padding: !Pd && 0 }}
    onClick={() => handleFunc()}
  >
    <IconButton>{Component}</IconButton>
  </Tooltip>
);
