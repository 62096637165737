import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NOTACAMERA } from "../Global/Icons";
import { GET_DATA } from "../Api/GetData";
import "./Style.css";
export default function BasicAccordion({
  title,
  show,
  Upload_Btn,
  Delete_Btn,
  Upload_Btn_Api,
  open = true,
  id,
}) {
  return (
    <Accordion defaultExpanded={open} id={id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div style={{ position: "relative", zIndex: "33" }}>
          <span className='Heaar_title ' style={{ fontWeight: "600" }}>
            {title}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>{show}</AccordionDetails>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "20px",
          gap: "20px",
        }}
      >
        {Upload_Btn_Api}
        {Upload_Btn}
        {Delete_Btn}
      </div>
    </Accordion>
  );
}
export function Custom_Accordion({
  title,
  show,
  Upload_Btn,
  Delete_Btn,
  Upload_Btn_Api,
  open = true,
  UserId,
  livliness_Img,
}) {
  const [VideoSrc, setVideoSrc] = React.useState("");
  // async function getVideo() {
  //   try {
  //     const response = await GET_DATA(`user/detail/videos/${UserId}`);
  //     setVideoSrc(response?.data?.liveliness);
  //   } catch (err) {}
  // }
  async function handleAccordionChange() {
    // console.log("hit hit ");
    if (!VideoSrc) {
      try {
        const response = await GET_DATA(`user/detail/videos/${UserId}`);
        setVideoSrc(response?.data?.liveliness);
      } catch (err) {}
    }
  }
  return (
    <Accordion defaultExpanded={open} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div style={{ position: "relative", zIndex: "33" }}>
          <span className='Heaar_title ' style={{ fontWeight: "600" }}>
            {title}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <>
          {" "}
          {VideoSrc ? (
            <video src={VideoSrc} controls={true} className='Vid'></video>
          ) : (
            <div className='VidCont'>
              <NOTACAMERA />
            </div>
          )}
          {livliness_Img}
        </>
      </AccordionDetails>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "20px",
          gap: "20px",
        }}
      >
        {Upload_Btn_Api}
        {Upload_Btn}
        {Delete_Btn}
      </div>
    </Accordion>
  );
}
export function BasicAccordion_INFO({ title, show, open = true }) {
  return (
    <Accordion defaultExpanded={open}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <span className='Heaar_title '>{title}</span>
      </AccordionSummary>
      <AccordionDetails>{show}</AccordionDetails>
    </Accordion>
  );
}

export function AccordionforRoleManagment({ title, show, open = true }) {
  return (
    <Accordion defaultExpanded={open}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <span className='Til '>{title}</span>
      </AccordionSummary>
      <AccordionDetails>{show}</AccordionDetails>
    </Accordion>
  );
}
