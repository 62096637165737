import React from "react";
import { QUESTIONS } from "../../../Global/Icons";
import { IsEmptyObject, NOTHING } from "../../../Global/Constant";
import { ToolTipWrapper } from "../../../Global/ToolTipWraper";
import { RENDERARRAY, RENDERCOUNTRYFLAG } from "./SanctionModal";
import SanctionTable from "./SanctionTable";
const PEP = ({
  showsanctions,
  setModalOpen,
  setCurrentData,
  UpdateStatus,
  analytic,
  setLoader,
  isSanctionRan,
}) => {
  function OpenModal(title, data) {
    setModalOpen(true);
    setCurrentData({
      title,
      data,
    });
  }
  return IsEmptyObject(showsanctions) ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          margin: "0 3%",
          marginTop: "3%",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Name:</span>
          </div>
          <div className="Center_Div Alng_Start">
            <span className="T_1">{showsanctions?.name}</span>
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Nationality:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERCOUNTRYFLAG(showsanctions?.nationality)}
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Gender:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.gender)}
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Birthday:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.birthDate)}
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Position:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.position)}
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Family Members:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.familyMembers)}
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Family Relative:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.familyRelatives)}
          </div>
        </div>

        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Passport Number: </span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.passportNumber)}
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">ID Number:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.idNumber)}
          </div>
        </div>
        <div className="Start_Div g_2_0">
          <div>
            <span className="T_1">Address:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.address)}
          </div>
        </div>
        <div
          className="Start_Div g_2_0 "
          style={{ marginTop: "3%", alignItems: "flex-start" }}
        >
          <div className="Alng_Start">
            <span className="T_1">Info:</span>
          </div>
          <div className="Center_Div Alng_Start">
            {RENDERARRAY(showsanctions?.notes)}
          </div>
        </div>
      </div>

      {showsanctions?.sanctions.length > 0 && (
        <div className="Space_Between_Div Alng_Start">
          <div
            style={{
              margin: "0 3%",
              marginTop: "3%",
              width: "100%",
            }}
          >
            <div>
              <span className="T_1">Sanction:</span>
            </div>
            <div style={{ marginTop: "2%" }}>
              <SanctionTable
                showsanctions={showsanctions}
                OpenModal={OpenModal}
              />
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "3%",
          }}
        >
          <div className="g_2_0 Center_Div">
            <div className="Center_Div">
              <span className="T_1">Verify Screening</span>
            </div>
            <button
              className="Active_Button"
              onClick={() => {
                UpdateStatus("approved");
                setLoader(true);
              }}
            >
              Accept
            </button>
            <button
              className="Active_Button"
              onClick={() => {
                UpdateStatus("rejected");
                setLoader(true);
              }}
            >
              Reject
            </button>
          </div>
          <div></div>
        </div>
        <div>
          <ToolTipWrapper
            PlaceHolder={"Data"}
            Component={<QUESTIONS />}
            handleFunc={() => {
              OpenModal("Data", analytic);
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      {isSanctionRan && <NOTHING />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "3%",
          }}
        >
          <div className="g_2_0 Center_Div">
            <div className="Center_Div">
              <span className="T_1">Verify Screening</span>
            </div>
            <button
              className="Fade_Button"
              disabled
              onClick={() => {
                UpdateStatus("approved");
                setLoader(true);
              }}
            >
              Accept
            </button>
            <button
              className="Fade_Button"
              disabled
              onClick={() => {
                UpdateStatus("rejected");
                setLoader(true);
              }}
            >
              Reject
            </button>
          </div>
        </div>
        <div>
          <ToolTipWrapper
            PlaceHolder={"Data"}
            Component={<QUESTIONS />}
            handleFunc={() => {
              OpenModal("Data", analytic);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PEP;
