import React, { useState, useEffect } from "react";
import "./Style.css";
// import { CROSSICON, SpinnerLoader } from "../Global/Icons";
import { FormControl, MenuItem, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { POST_API } from "../Api/GetData.js";
import { ERROR, SUCCESS } from "./Msg";
import { CROSSICON, SpinnerLoader } from "../Global/Icons.js";
const BankDetailModal = ({ setOpen, UserId, handleClose, handleTableData }) => {
  const [accountNumber, setaccountNumber] = useState("");
  const [accountType, setaccountType] = useState("");
  const [branchCode, setbranchCode] = useState("");
  const [bankName, setbankName] = useState("");
  const [dis, setDis] = useState("");
  const [validationEr, setValidationEr] = useState("");
  useEffect(() => {}, []);

  const updateUserData = async (getData) => {
    // console.log(a);
    handleClose();
    try {
      const response = await handleTableData(getData);
      setDis(false);
    } catch (err) {
      setDis(false);
      handleClose();
    }
    //  setUserData({
    //    ...userData,
    //    name,
    //    idNumber,
    //    accountNumber,
    //    accountType,
    //    branchCode,
    //    bankName,
    //    userId,
    //  });
  };

  return (
    <div className="F_G_20 COLMN" style={{ margin: "0", gap: "20px" }}>
      <SpinnerLoader state={dis} />
      <div className="Space_Between_Div">
        <div>
          <h3>Bank Details</h3>
        </div>
        <div onClick={() => setOpen(false)}>
          <CROSSICON />
        </div>
      </div>
      {validationEr && <div className="ER_Cont">{validationEr}</div>}

      <div className="">
        <input
          className="INT_CONTAINER"
          value={accountNumber}
          placeholder="Account Number"
          onChange={(e) => setaccountNumber(e.target.value)}
        />
      </div>

      <div className="">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Account Type</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={accountType}
            label="Account Type"
            onChange={(e) => {
              setaccountType(e.target.value);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              classes: {
                paper: "select-paper",
              },
            }}
          >
            <MenuItem value={"current"}>01 - current / cheque account</MenuItem>
            <MenuItem value={"savings account"}>02 - savings account</MenuItem>
            <MenuItem value={"transmission account'"}>
              03 - transmission account
            </MenuItem>
            <MenuItem value={"bond account"}>04 - bond account</MenuItem>
            <MenuItem value={"subscription share"}>
              06 - subscription share
            </MenuItem>
            <MenuItem value={"other"}>00 - if this is not known</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="">
        <input
          className="INT_CONTAINER"
          value={branchCode}
          placeholder="Branch Code"
          onChange={(e) => setbranchCode(e.target.value)}
        />
      </div>
      <div className="">
        <input
          placeholder="Bank Name"
          className="INT_CONTAINER"
          value={bankName}
          onChange={(e) => setbankName(e.target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {" "}
        <button
          disabled={dis}
          className={"Fade_Button"}
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          //   className="fillBtn"
          disabled={dis}
          style={{ background: dis ? "grey" : "#38aee0" }}
          className={dis ? "Fade_Button" : "Active_Button"}
          onClick={() => {
            // setDis(true);
            UserId?.setLoading(true);
            if (
              accountNumber !== "" &&
              accountType !== "" &&
              branchCode !== "" &&
              bankName !== ""
            ) {
              // handle_Source_Income();

              updateUserData({
                idNumber: UserId?._Id,
                name: UserId?.Sanction,
                accountNumber: accountNumber,
                accountType: accountType,
                branchCode: branchCode,
                bankName: bankName,
                userId: UserId?.UserId,
              });
              setValidationEr("");
            } else {
              setValidationEr("Please fill all the details.");
              setDis(false);
            }
          }}
        >
          Save
        </button>
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};
export default BankDetailModal;

export const BANK_DETAIL_MODAL = ({
  setOpen,
  UserId,
  handleClose,
  handleTableData,
}) => {
  const [accountNumber, setaccountNumber] = useState("");
  const [IdNumber, setIdNumber] = useState("");
  const [accountType, setaccountType] = useState("");
  const [branchCode, setbranchCode] = useState("");
  const [name, setName] = useState("");
  const [bankName, setbankName] = useState("");
  const [dis, setDis] = useState("");
  const [validationEr, setValidationEr] = useState("");
  // useEffect(() => {}, []);

  const updateUserData = async (getData) => {
    // console.log(a);
    handleClose();
    try {
      const response = await handleTableData(getData);
      setDis(false);
    } catch (err) {
      setDis(false);
      handleClose();
    }
    //  setUserData({
    //    ...userData,
    //    name,
    //    idNumber,
    //    accountNumber,
    //    accountType,
    //    branchCode,
    //    bankName,
    //    userId,
    //  });
  };

  return (
    <div className="F_G_20 COLMN" style={{ margin: "0", gap: "20px" }}>
      <SpinnerLoader state={dis} />
      <div className="Space_Between_Div">
        <div>
          <h3>Bank Details</h3>
        </div>
        <div onClick={() => setOpen(false)}>
          <CROSSICON />
        </div>
      </div>
      {validationEr && <div className="ER_Cont">{validationEr}</div>}
      <div className="">
        <input
          className="INT_CONTAINER"
          value={name}
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="">
        <input
          className="INT_CONTAINER"
          value={IdNumber}
          placeholder={
            UserId?.UserId == "businessModal"
              ? "Company Registration Number"
              : "ID Number"
          }
          onChange={(e) => setIdNumber(e.target.value)}
        />
      </div>

      <div className="">
        <input
          className="INT_CONTAINER"
          value={accountNumber}
          placeholder="Account Number"
          onChange={(e) => setaccountNumber(e.target.value)}
        />
      </div>

      <div className="">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Account Type</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={accountType}
            label="Account Type"
            onChange={(e) => {
              setaccountType(e.target.value);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              classes: {
                paper: "select-paper",
              },
            }}
          >
            <MenuItem value={"current"}>01 - current / cheque account</MenuItem>
            <MenuItem value={"savings account"}>02 - savings account</MenuItem>
            <MenuItem value={"transmission account'"}>
              03 - transmission account
            </MenuItem>
            <MenuItem value={"bond account"}>04 - bond account</MenuItem>
            <MenuItem value={"subscription share"}>
              06 - subscription share
            </MenuItem>
            <MenuItem value={"other"}>00 - if this is not known</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="">
        <input
          className="INT_CONTAINER"
          value={branchCode}
          placeholder="Branch Code"
          onChange={(e) => setbranchCode(e.target.value)}
        />
      </div>
      <div className="">
        <input
          placeholder="Bank Name"
          className="INT_CONTAINER"
          value={bankName}
          onChange={(e) => setbankName(e.target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {" "}
        <button
          disabled={dis}
          className={"Fade_Button"}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </button>
        <button
          //   className="fillBtn"
          disabled={dis}
          style={{ background: dis ? "grey" : "#38aee0" }}
          className={dis ? "Fade_Button" : "Active_Button"}
          onClick={() => {
            // setDis(true);
            UserId?.setLoading(true);
            if (
              accountNumber !== "" &&
              accountType !== "" &&
              branchCode !== "" &&
              bankName !== "" &&
              name !== "" &&
              IdNumber !== ""
            ) {
              // handle_Source_Income();

              updateUserData({
                idNumber: IdNumber,
                name: name,
                accountNumber: accountNumber,
                accountType: accountType,
                branchCode: branchCode,
                bankName: bankName,
                userId: UserId?.UserId,
              });
              setValidationEr("");
            } else {
              setValidationEr("Please fill all the details.");
              setDis(false);
            }
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};
