import React, { useEffect, useRef, useState } from "react";
import CustomDialog from "./CustomDialog";
import { useNavigate } from "react-router";
import { connect, useDispatch } from "react-redux";
import {
  setTutorialStep,
  toggleViewEntityTutorial,
} from "../../redux/customizer/Action";
import "./styles.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { GET_DATA, POST_API } from "../../Api/GetData";

const ViewEntityTutorial = ({ isViewEntityTutorialActive, step, setTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const endTutorial = 18;
  const [tutorial, setTutorial] = useState(isViewEntityTutorialActive);
  const [tutorialSteps, setTutorialSteps] = useState(step);
  const [highlight, setHighlight] = useState({});
  const highlightRef = useRef(null);
  // tutorial steps start from 1 and go to 4 to match case numbers
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [firstID, setFirstID] = useState(null);
  let setPosition = null;

  useEffect(() => {
    dispatch(toggleViewEntityTutorial(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setTutorialStep(tutorialSteps));
  }, [dispatch, tutorialSteps]);

  useEffect(() => {
    const handleHighlight = () => {
      const highlightResult = showCase();
      setHighlight(highlightResult);
    };
    const observer = new MutationObserver(handleHighlight);
    const config = { childList: true, subtree: true };

    observer.observe(document.body, config);

    const intervalId = setInterval(() => {
      handleHighlight();
    }, 100);

    return () => {
      observer.disconnect();
      clearInterval(intervalId);
    };
  }, [tutorialSteps]);

  useEffect(() => {
    if (highlight && highlightRef.current) {
      window.scrollTo({
        top: highlight.top - window.innerHeight / 2 + highlight.height / 2,
        behavior: "smooth",
      });
    }
  }, [highlight]);

  const closeAlert = () => {
    setOpenAlert(false);
    dispatch(toggleViewEntityTutorial(false));
  };

  useEffect(() => {
    async function getTableData() {
      const response = await POST_API(`retrieve/business/all?&&page=1`);
      setFirstID(response.data.businesses[0]._id ?? null);
      if (firstID === null) {
        setOpenAlert(true);
      }
    }
    getTableData();
    setLoading(false);
  }, [firstID]);

  const tutorialTitle = () => {
    switch (tutorialSteps) {
      case 1:
        return "Viewing Entity Details";
      case 2:
        return "Entity Summary Overview";
      case 3:
        return "Create and Link Individual/Entity";
      case 4:
        return "Link Existing Individual/Entity";
      case 5:
        return "Copy Workflow Link";
      case 6:
        return "Update Entity Data";
      case 7:
        return "Organizational Diagram";
      case 8:
        return "Export Organizational Diagram";
      case 9:
        return "Download Entity Summary";
      case 10:
        return "Update Final Status";
      case 11:
        return "Edit Source of Funds";
      case 12:
        return "Manage Entity Address";
      case 13:
        return "Customer Due Diligence (CDD) Features";
      case 14:
        return "Enhanced Due Diligence (EDD) Features";
      case 15:
        return "Manage Documents";
      case 16:
        return "Track Events";
      case 17:
        return "Create Internal Notes";
      case 18:
        return "View Timeline of Activities";
      // can add more cases
      default:
        return "";
    }
  };

  const tutorialContent = () => {
    switch (tutorialSteps) {
      case 1:
        return "Click the eye icon to view details.";
      case 2:
        return "The Summary tab provides a brief overview of the entity.";
      case 3:
        return "Use this button to add a new Entity/Individual and link them to the current entity.";
      case 4:
        return "Use this button to link an existing Entity/Individual to the current entity.";
      case 5:
        return "Click this button to generate a personalized workflow link.";
      case 6:
        return `To manually update the entity's data, click here to access the data editing page.`;
      case 7:
        return "Use this button to view the organogram for the entity.";
      case 8:
        return (
          <div>
            Exporting the organogram will produce a PDF/PNG as seen in the
            example below.
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="Organogram Example"
                src={require("./Images/Organogram.png")}
                width="70%"
                height="70%"
              />
            </div>
          </div>
        );
      case 9:
        return "Download a summary report of the entity by clicking here.";
      case 10:
        return `Manually update the entity's status by clicking here.`;
      case 11:
        return `Manually update the entity's source of funds by clicking here.`;
      case 12:
        return `View and update the entity's address manually at this location.`;
      case 13:
        return `Access and perform customer due diligence features such as Sanction/PEP screening and database checks. Note that verify actions may consume credits.`;
      case 14:
        return "Explore Enhanced Due Diligence features like media searches. Verify actions may consume credits.";
      case 15:
        return `Access additional uploaded documents for the entity at this location.`;
      case 16:
        return "Track any event workflows sent to the entity in this section.";
      case 17:
        return "Create internal notes related to the entity on this page.";
      case 18:
        return `Track changes to the entity's account in this section.`;
      // can add more cases
      default:
        return "";
    }
  };
  const dialogSize = () => {
    switch (tutorialSteps) {
      default:
        return "xs";
    }
  };

  const showCase = () => {
    let element = null;
    switch (tutorialSteps) {
      case 1:
        element = document.getElementById("EntityView-0");
        setPosition = "Left";
        break;
      case 2:
        element = document.getElementById("EntitySummary");
        setPosition = "Right";
        break;
      case 3:
        element = document.getElementById("EntityLinkNew");
        setPosition = "Right";
        break;
      case 4:
        element = document.getElementById("EntityLink");
        setPosition = "Right";
        break;
      case 5:
        element = document.getElementById("EntityCopyWF");
        setPosition = "Right";
        break;
      case 6:
        element = document.getElementById("EntityUpdate");
        setPosition = "Right";
        break;
      case 7:
        element = document.getElementById("EntityOrg");
        setPosition = "Left";
        break;
      case 8:
        element = document.getElementById("EntityOrgExport");
        setPosition = "BottomLeft";
        break;
      case 9:
        element = document.getElementById("EntityDownload");
        setPosition = "Left";
        break;
      case 10:
        element = document.getElementById("EntityStatus");
        setPosition = "TopLeft";
        break;
      case 11:
        element = document.getElementById("EntityFunds");
        setPosition = "TopLeft";
        break;
      case 12:
        setTab("Address");
        element = document.getElementById("EntityAddress");
        setPosition = "Right";
        break;
      case 13:
        setTab("Info");
        element = document.getElementById("EntityCDD");
        setPosition = "Right";
        break;
      case 14:
        setTab("EDD");
        element = document.getElementById("EntityEDD");
        setPosition = "Right";
        break;
      case 15:
        setTab("Documents");
        element = document.getElementById("EntityDocuments");
        setPosition = "Left";
        break;
      case 16:
        setTab("Event");
        element = document.getElementById("EntityEvents");
        setPosition = "Left";
        break;
      case 17:
        setTab("Notes");
        element = document.getElementById("EntityNotes");
        setPosition = "Left";
        break;
      case 18:
        setTab("Timeline");
        element = document.getElementById("EntityTimeline");
        setPosition = "Left";
        break;
      default:
        break;
    }
    if (element) {
      const rect = element.getBoundingClientRect();
      return {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
      };
    }
    return {};
  };

  const nextStep = () => {
    if (tutorialSteps < endTutorial) {
      setTutorialSteps((prevStep) => prevStep + 1);
      dispatch(setTutorialStep(tutorialSteps + 1));
      if (tutorialSteps === 1) {
        navigate("/entity-details", { state: { _id: firstID } });
      }
      if (tutorialSteps === 7) {
        navigate("/organogram");
      }
      if (tutorialSteps === 8) {
        navigate("/entity-details", { state: { _id: firstID } });
      }
      if (tutorialSteps > 10) {
        switch (tutorialSteps) {
          case 11:
            setTab("Summary");
            break;
          case 12:
            setTab("Address");
            break;
          case 13:
            setTab("Info");
            break;
          case 14:
            setTab("EDD");
            break;
          case 15:
            setTab("Documents");
            break;
          case 16:
            setTab("Event");
            break;
          case 17:
            setTab("Notes");
            break;
          case 18:
            setTab("Timeline");
            break;
          default:
            break;
        }
      }
    } else {
      closeTutorial();
    }
  };
  const prevStep = () => {
    if (tutorialSteps !== 1) {
      setTutorialSteps((prevStep) => prevStep - 1);
      dispatch(setTutorialStep(tutorialSteps - 1));
      if (tutorialSteps === 2) {
        navigate("/entity-management");
      }
      if (tutorialSteps === 8) {
        navigate("/entity-details", { state: { _id: firstID } });
      }
      if (tutorialSteps === 9) {
        navigate("/organogram");
      }
      if (tutorialSteps > 11) {
        switch (tutorialSteps) {
          case 12:
            setTab("Summary");
            break;
          case 13:
            setTab("Address");
            break;
          case 14:
            setTab("Info");
            break;
          case 15:
            setTab("EDD");
            break;
          case 16:
            setTab("Documents");
            break;
          case 17:
            setTab("Event");
            break;
          case 18:
            setTab("Notes");
            break;
          default:
            break;
        }
      }
    } else {
      setTutorial(false);
      setTutorialSteps(1);
    }
  };

  const closeTutorial = () => {
    navigate("/entity-management");
    setTutorial(false);
    dispatch(toggleViewEntityTutorial(false));
  };
  const handleOverlayClick = (e, step) => {
    e.preventDefault();
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {firstID ? (
        <div>
          <CustomDialog
            open={tutorial}
            onClose={closeTutorial}
            onNext={nextStep}
            onBack={prevStep}
            title={tutorialTitle()}
            content={tutorialContent()}
            step={tutorialSteps}
            end={endTutorial}
            showCase={showCase()}
            size={dialogSize()}
            setPosition={setPosition}
          />

          {highlight && (
            <div
              style={{
                position: "absolute",
                top: highlight.top,
                left: highlight.left,
                width: highlight.width,
                height: highlight.height,
                border: "2px solid blue",
                zIndex: 9999,
                borderRadius: tutorialSteps !== 10 ? "5px" : "15px",
                boxShadow: "0 0 10px 0 rgba(0, 0, 255, 0.5)",
              }}
              className="pulse-animation"
              onClick={(e) => handleOverlayClick(e, tutorialSteps)}
            ></div>
          )}
        </div>
      ) : (
        <Dialog open={openAlert} onClose={closeAlert}>
          <DialogTitle sx={{ fontSize: 24 }}>No Entities Found</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You must have an entity created before proceeding with this
              tutorial
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isViewEntityTutorialActive:
    state.CustomizerReducer.isViewEntityTutorialActive,
});

const mapDispatchToProps = {
  toggleViewEntityTutorial,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEntityTutorial);
