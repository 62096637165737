import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";

export default function CustomDialog({
  open,
  onClose,
  onNext,
  onBack,
  title,
  content,
  step,
  end,
  showCase,
  size,
  setPosition,
  btnBack,
}) {
  const offsetY = 40;
  const offsetX = 40;
  const dialogWidth = 444;
  const dialogHeight = 160;
  const theme = useTheme();
  const finalWidth = dialogWidth + offsetX;
  const finalHeight = dialogHeight + offsetY;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose(event, reason);
  };

  const elementTop = showCase.top;
  const elementLeft = showCase.left;
  const elementRight = showCase.right;
  const elementBottom = showCase.bottom;

  // Default positions (middle of the screen)
  let dialogPosition = {};
  switch (true) {
    // Check if dialog fits on the right side
    case setPosition === "Right":
      dialogPosition = {
        top: elementTop - offsetY,
        left: elementRight,
      };
      break;

    // Check if dialog fits on the left side
    case setPosition === "Left":
      dialogPosition = {
        top: elementTop - offsetY,
        left: elementLeft - finalWidth,
      };
      break;

    // Check if dialog fits above
    case setPosition === "Top":
      dialogPosition = {
        top: elementTop - 250,
        left: elementLeft - offsetX,
      };
      break;

    // Check if dialog fits below
    case setPosition === "Bottom":
      dialogPosition = {
        top: elementBottom - 20,
        left: elementLeft - offsetX,
      };
      break;
    case setPosition === "BottomLeft":
      dialogPosition = {
        top: elementBottom - 20,
        left: elementLeft - dialogWidth,
      };
      break;
    case setPosition === "TopLeft":
      dialogPosition = {
        top: elementTop - 250,
        left: elementLeft - 440,
      };
      break;
    // If none of the above conditions are met, position dialog in the middle
    default:
      dialogPosition = {
        top: window.innerHeight / 2,
        left: window.innerWidth / 2,
        transform: "translate(-50%, -50%)",
      };
      break;
  }

  const dialogStyle = {
    top: `${dialogPosition.top}px`,
    left: `${dialogPosition.left}px`,
    position: "absolute",
    transform: dialogPosition.transform ? dialogPosition.transform : "none",
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        maxWidth={size}
        fullWidth={true}
        disableEscapeKeyDown={true}
        PaperProps={{ style: dialogStyle }}
        disableEnforceFocus={true}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: 0 }}>
          {title}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "primary",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>{content}</DialogContent>
        <MobileStepper
          variant='text'
          sx={{ bgcolor: "transparent" }}
          steps={end}
          position='static'
          activeStep={step - 1}
          nextButton={
            step === end ? (
              <Button size='small' onClick={handleClose}>
                Done
              </Button>
            ) : (
              <Button size='small' onClick={onNext} disabled={step === end}>
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            )
          }
          backButton={
            <Button
              size='small'
              onClick={onBack}
              disabled={step === 1 || btnBack}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Dialog>
    </>
  );
}
