import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { POST_API } from "./Api/GetData";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: "AIzaSyDU8t-gY2DsEoLSDScOWJCNBkB8k6tvxRY",
  authDomain: "cms-id.firebaseapp.com",
  projectId: "cms-id",
  storageBucket: "cms-id.appspot.com",
  messagingSenderId: "217924303586",
  appId: "1:217924303586:web:acb06bef1a2e0d27a33cbb",
  measurementId: "G-3E5ZX1M6YN",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.Vpid_Key,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);

        POST_API("/notification/add/token", { token: currentToken });
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
