import {
  UPDATE_CUSTOMIZER_STATE,
  ACCESS_OPTIONS,
  GET_USER_DATA,
  GET_CREDIT_SCORE,
  GET_PERKS_ACCESS,
  TOGGLE_SHOW_RISK_STORE,
  TOGGLE_WORKFLOW_TUTORIAL,
  TOGGLE_CREATE_INDIVIDUAL_TUTORIAL,
  TOGGLE_VIEW_INDIVIDUAL_TUTORIAL,
  TOGGLE_CREATE_ENTITY_TUTORIAL,
  TOGGLE_VIEW_ENTITY_TUTORIAL,
  TOGGLE_INDIVIDUAL_ONBOARDING_TUTORIAL,
  SET_TUTORIAL_STEP,
  SET_INDIVIDUAL_SCORE,
  SET_ENTITY_SCORE,
  SET_PAGINATION,
  SET_PAGINATION_ENTITY,
} from "../constants";

const INIT_STATE = {
  customizerState: false,
  Access: [],
  UserDetail: [],
  CreaditScore: {},
  perkAccess: [],
  ShowRiskScore: true,
  isWorkFlowTutorialActive: false,
  isCreateIndividualTutorialActive: false,
  isViewIndividualTutorialActive: false,
  isCreateEntityTutorialActive: false,
  isViewEntityTutorialActive: false,
  isIndividualOnboardingTutorialActive: false,
  tutorialStep: 1,
  Individual_Score: {},
  Entity_Score: {},
  Individual_Pagination: 1,
  Entity_Pagination_Value: 1,
};

const CustomizerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMIZER_STATE:
      return {
        ...state,
        customizerState: !state.customizerState,
      };
    case ACCESS_OPTIONS:
      return {
        ...state,
        Access: action.payload,
      };
    case GET_USER_DATA:
      return {
        ...state,
        UserDetail: action.payload,
      };
    case GET_CREDIT_SCORE:
      return {
        ...state,
        CreaditScore: action.payload,
      };
    case GET_PERKS_ACCESS:
      return {
        ...state,
        perkAccess: action.payload,
      };
    case SET_PAGINATION_ENTITY:
      return {
        ...state,
        Entity_Pagination_Value: action.payload,
      };
    case SET_PAGINATION:
      return {
        ...state,
        Individual_Pagination: action.payload,
      };

    case TOGGLE_SHOW_RISK_STORE:
      return {
        ...state,
        ShowRiskScore: action.payload,
      };
    case TOGGLE_WORKFLOW_TUTORIAL:
      return {
        ...state,
        isWorkFlowTutorialActive: !state.isWorkFlowTutorialActive,
      };
    case TOGGLE_CREATE_INDIVIDUAL_TUTORIAL:
      return {
        ...state,
        isCreateIndividualTutorialActive:
          !state.isCreateIndividualTutorialActive,
      };
    case TOGGLE_VIEW_INDIVIDUAL_TUTORIAL:
      return {
        ...state,
        isViewIndividualTutorialActive: !state.isViewIndividualTutorialActive,
      };
    case TOGGLE_CREATE_ENTITY_TUTORIAL:
      return {
        ...state,
        isCreateEntityTutorialActive: !state.isCreateEntityTutorialActive,
      };
    case TOGGLE_VIEW_ENTITY_TUTORIAL:
      return {
        ...state,
        isViewEntityTutorialActive: !state.isViewEntityTutorialActive,
      };
    case TOGGLE_INDIVIDUAL_ONBOARDING_TUTORIAL:
      return {
        ...state,
        isIndividualOnboardingTutorialActive:
          !state.isIndividualOnboardingTutorialActive,
      };
    case SET_TUTORIAL_STEP:
      return {
        ...state,
        tutorialStep: action.payload,
      };
    case SET_INDIVIDUAL_SCORE:
      return {
        ...state,
        Individual_Score: action.payload,
      };
    case SET_ENTITY_SCORE:
      return {
        ...state,
        Entity_Score: action.payload,
      };

    default:
      return state;
  }
};

export default CustomizerReducer;
