import React, { useEffect, useState } from "react";
import { ToolTipWrapper } from "../../../Global/ToolTipWraper";
import { CROSSICON } from "../../../Global/Icons";
import { makeHyperLink } from "../../Verificatoin_Detail/Info";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ERROR } from "../../../CustomComponent/Msg";
import { GET_COUNTRY_FLAG, ReNameRenderObject } from "../../../Global/Constant";
import { renderObject } from "../../Verificatoin_Detail/Info";
const SanctionModal = ({ viewData, setOpen }) => {
  function renderObject(obj) {
    if (typeof obj !== "object" || obj === null) {
      return null;
    }

    return (
      <ul>
        {Object.entries(obj).map(([key, value]) => (
          <li key={key}>
            {!Array.isArray(value) &&
            typeof value === "object" &&
            value !== null ? (
              <>
                <strong style={{ textTransform: "capitalize" }}>
                  {ReNameRenderObject(key)}:
                </strong>
                {renderObject(value)}
              </>
            ) : Array.isArray(value) ? (
              <>
                <strong style={{ textTransform: "capitalize" }}>
                  {ReNameRenderObject(key)}:
                </strong>
                <ul>
                  {value.map((element) => (
                    <li key={JSON.stringify(element)}>
                      {typeof element === "object" && element !== null
                        ? renderObject(element)
                        : `${element}`}
                    </li>
                  ))}
                </ul>
              </>
            ) : key === "IDPhoto" ? (
              <div className="PHOTO_ID">
                <strong>{ReNameRenderObject(key)}:</strong>
                <img
                  src={value}
                  alt="ID Photo"
                  className="InfoIMG"
                  style={{ width: "20rem", objectFit: "contain" }}
                />
              </div>
            ) : (
              <>
                <strong style={{ textTransform: "capitalize" }}>
                  {ReNameRenderObject(key)}:
                </strong>{" "}
                {typeof value === "boolean"
                  ? JSON.stringify(value)
                  : makeHyperLink(value)}
              </>
            )}
          </li>
        ))}
      </ul>
    );
  }

  //   console.log(viewData, "ds");

  return (
    <div style={{ maxHeight: "90vh", overflow: "auto" }}>
      <div className="Space_Between_Div">
        <div>
          <h2>{viewData?.title}</h2>
        </div>
        <ToolTipWrapper
          Component={<CROSSICON />}
          PlaceHolder={"Cancel"}
          handleFunc={() => {
            setOpen(false);
          }}
        />
      </div>
      <div>
        {/* {Render(viewData?.data)} */}
        {viewData?.data?.map((element) => (
          <li key={JSON.stringify(element)}>
            {typeof element === "object" && element !== null
              ? renderObject(element)
              : `${element}`}
          </li>
        ))}
      </div>
    </div>
  );
};

export default SanctionModal;

export function NoteModalSanction({ setOpen, viewData, UserId }) {
  const [editorData, setEditorData] = useState("");
  const [pdfNote, setPdfNotes] = useState("");
  const handleEditorChange = (event, editor) => {
    const htmlData = editor.getData();
    setEditorData(htmlData);

    // creating raw data without html
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlData;
    const plainText = tempElement.textContent || tempElement.innerText || "";
    setPdfNotes(plainText);

    // console.log(plainText,"----");
  };

  useEffect(() => {
    if (UserId) {
      setEditorData(UserId);
    }
  }, [UserId]);
  return (
    <div style={{ maxHeight: "90vh", overflow: "auto" }}>
      <div className="Space_Between_Div">
        <div>
          <h2>Analytics</h2>
        </div>
        <ToolTipWrapper
          Component={<CROSSICON />}
          PlaceHolder={"Cancel"}
          handleFunc={() => {
            setOpen(false);
          }}
        />
      </div>
      <div>
        <CKEditor
          editor={ClassicEditor}
          onReady={(editor) => {
            // console.log("Editor is ready to use!", editor);
          }}
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "underline",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "blockQuote",
                "|",
                "undo",
                "redo",
                // You can customize this list to include/exclude items
              ],
            },
            // Optional: Disable other features if necessary
            image: {
              toolbar: ["imageTextAlternative"], // Keep only the alt text option
            },
          }}
          data={editorData}
          onChange={handleEditorChange}
        />
      </div>
      <div className="End_Div MT_20">
        <button
          className="Active_Button"
          onClick={() => {
            viewData.handleUpdateNote(editorData, pdfNote);
            setOpen(false);
            viewData.setLoader(true);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export const RENDERARRAY = (DATA) => {
  return (
    <>{Array.isArray(DATA) && DATA?.map((data) => <span>{`${data} `}</span>)}</>
  );
};

export const RENDERCOUNTRYFLAG = (DATA) => {
  return (
    <>
      {Array.isArray(DATA) &&
        DATA?.map((data) => (
          <GET_COUNTRY_FLAG className="emojiFlag" code={data} />
        ))}
    </>
  );
};

export function RENDERANYTHIN(obj) {
  if (typeof obj !== "object" || obj === null) {
    return null;
  }

  return (
    <ul>
      {Object.entries(obj).map(([key, value]) => (
        <li key={key}>
          {!Array.isArray(value) &&
          typeof value === "object" &&
          value !== null ? (
            <>
              <strong style={{ textTransform: "capitalize" }}>
                {ReNameRenderObject(key)}:
              </strong>
              {RENDERANYTHIN(value)}
            </>
          ) : Array.isArray(value) ? (
            <>
              <strong style={{ textTransform: "capitalize" }}>
                {ReNameRenderObject(key)}:
              </strong>
              <ul>
                {value.map((element) => (
                  <li key={JSON.stringify(element)}>
                    {typeof element === "object" && element !== null
                      ? RENDERANYTHIN(element)
                      : `${element}`}
                  </li>
                ))}
              </ul>
            </>
          ) : key === "IDPhoto" ? (
            <div className="PHOTO_ID">
              <strong>{ReNameRenderObject(key)}:</strong>
              <img
                src={value}
                alt="ID Photo"
                className="InfoIMG"
                style={{ width: "20rem", objectFit: "contain" }}
              />
            </div>
          ) : (
            <>
              <strong style={{ textTransform: "capitalize" }}>
                {ReNameRenderObject(key)}:
              </strong>{" "}
              {typeof value === "boolean"
                ? JSON.stringify(value)
                : makeHyperLink(value)}
            </>
          )}
        </li>
      ))}
    </ul>
  );
}
