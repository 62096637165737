import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GET_COUNTRY_FLAG, HANDLE_NAVIGATE } from "../../../Global/Constant";
import { RENDERARRAY } from "./SanctionModal";
import { ToolTipWrapper } from "../../../Global/ToolTipWraper";
import { EYEICON } from "../../../Global/Icons";
const SanctionTable = ({ showsanctions, OpenModal }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                className="HeaderCenter "
                style={{
                  background: "rgba(96, 96, 96, 0.1)",
                  color: "rgba(70, 78, 95, 1)",
                  fontSize: "20px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Country
              </TableCell>
              <TableCell
                className="HeaderCenter "
                style={{
                  background: "rgba(96, 96, 96, 0.1)",
                  color: "rgba(70, 78, 95, 1)",
                  fontSize: "20px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Authority
              </TableCell>
              <TableCell
                className="HeaderCenter "
                style={{
                  background: "rgba(96, 96, 96, 0.1)",
                  color: "rgba(70, 78, 95, 1)",
                  fontSize: "20px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Program/Reason
              </TableCell>
              <TableCell
                className="HeaderCenter  "
                style={{
                  background: "rgba(96, 96, 96, 0.1)",
                  color: "rgba(70, 78, 95, 1)",
                  fontSize: "20px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showsanctions &&
              showsanctions?.sanctions.map((data, index) => {
                var ARR = [data];

                return (
                  <TableRow key={index} className="Space_Between_Div ">
                    <TableCell className="f_one Center_Div  P_0">
                      <GET_COUNTRY_FLAG code={data?.country?.[0]} />
                    </TableCell>
                    <TableCell className="f_two Center_Div P_0">
                      <span
                        className="T_1"
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => HANDLE_NAVIGATE(data?.sourceUrl?.[0])}
                      >
                        {RENDERARRAY(data?.authority)}
                      </span>
                    </TableCell>
                    <TableCell className="f_two Center_Div P_0">
                      {RENDERARRAY(data?.program)}
                    </TableCell>
                    <TableCell className="f_two Center_Div P_0">
                      <ToolTipWrapper
                        Component={<EYEICON />}
                        handleFunc={() => {
                          OpenModal("Reason", data?.reason);
                        }}
                        Pd="3"
                        PlaceHolder={"View detail"}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SanctionTable;
