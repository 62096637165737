import { Modal } from "@mui/material";
import { Box } from "@mui/material";
import "../css/App.css";
function ModalWraper({
  open,
  handleClose,
  setOpen,
  handleTableData,
  Component,
  UserId,
  viewData,
  viewId = "",
  dl,
  CusClass = "Nrm",
  Cust_Img,
  dis
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={!dl ? CusClass : "DelMod"}>
        <Component
          setOpen={setOpen}
          handleTableData={handleTableData}
          viewData={viewData}
          UserId={UserId}
          viewId={viewId}
          handleClose={handleClose}
          Cust_Img={Cust_Img}
          dis={dis}
        />
      </Box>
    </Modal>
  );
}

export default ModalWraper;
