import React, { useEffect, useState } from "react";
import { ADD_ICONS, CROSSICON, SpinnerLoader } from "../../Global/Icons";
import MultipleSelectChip, {
  CUSTOM_SELECTOR,
} from "../../CustomComponent/PureComponent";
import "../../CustomComponent/Style.css";
import "./Style.css";
import { GET_DATA, POST_API } from "../../Api/GetData";
import { ERROR, SUCCESS } from "../../CustomComponent/Msg";
import { ToolTipWrapper } from "../../Global/ToolTipWraper";
import { useLocation, useNavigate } from "react-router";
import CustomCheckbox from "../../components/forms/custom-elements/CustomCheckbox";
import { isValidUrl, Custom_Slice_String } from "../../Global/Const";
import { USEIT } from "../Verificatoin_Detail/Info";
const WebhookModal = (props) => {
  const [loading, setLoading] = useState("");
  const [name, setName] = useState("");
  const [types, setType] = useState("");
  const [secret, setsecret] = useState("");
  const [domainOptions, setDomainOptions] = useState([]);
  const [headers, setHeader] = useState({ key: "", value: "" });
  const [target, setTarget] = useState("");
  const [action, setAction] = useState([]);
  const [TableRowData, setTableRowData] = useState([]);
  const [status, setStatus] = useState("");
  const [multiple_header, setMultipleHeader] = useState([]);
  const [multipleDomain, setMultipleDomain] = useState([]);
  const [reschedule, setreschedule] = useState(false);
  const navigate = useNavigate();
  const [openHeader, setOpenHeader] = useState(false);
  const [multipleDomainOptions, setmultipleDomainOptions] = useState([]);
  const handleCloseModal = () => setOpenHeader(false);
  const { setOpen, handleTableData } = props;

  const btnDis =
    Boolean(action) &&
    Boolean(name) &&
    Boolean(secret) &&
    // Boolean(domain) &&
    Boolean(types);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  async function getEventData() {
    try {
      const response = await GET_DATA("/get/all/events");
      setTableRowData(response?.data);
      let domain = response?.data?.map((data) => ({
        label: data?.domain,
        value: data?.domain,
      }));
      setDomainOptions(domain);

      //   setLoading(false);
    } catch (err) {
      //   setLoading(false);
    }
  }

  function SHOWIT(obj) {
    return multipleDomain?.map((data, key) => USEIT(data?.payload, key));
  }
  async function handleAddEvent() {
    try {
      setLoading(false);

      const response = await POST_API("create/webhook/data", {
        name,
        target,
        event: multipleDomain,
        types,
        secret,
        headers: multiple_header,
        status,
        reschedule,
      });
      SUCCESS("Webhook Created");
      navigate("/webhooks-management");
      // handleTableData();
    } catch (err) {
      setLoading(false);
    }
  }
  useEffect(() => {
    getEventData();
  }, []);

  return (
    <div className="F_g R_CL">
      <SpinnerLoader state={loading} />

      <div className="Colm GP__20 __W_H ___FW">
        <div className={"InputContainer W_D__F_"}>
          <input
            className={"InputField Rs_Full_Width"}
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={"InputContainer W_D__F_"}>
          <input
            className={"InputField Rs_Full_Width"}
            placeholder="Target"
            value={target}
            onChange={(e) => {
              setTarget(e.target.value);
            }}
          />
        </div>

        <div className={"InputContainer W_D__F_"}>
          <input
            className={"InputField Rs_Full_Width"}
            placeholder="Secret"
            value={secret}
            onChange={(e) => {
              setsecret(e.target.value);
            }}
          />
        </div>
        {/* <div className="Space_Between_Div G_20"> */}
        <div className="W_D__F">
          <CUSTOM_SELECTOR
            name={"Entity"}
            setter={(value) => setType(value)}
            value={types}
            options={[
              { value: "individual", label: "Individual" },
              { value: "entity", label: "Entity" },
            ]}
          />
        </div>

        <span>Headers</span>
        <div className="Space_Between_Div G_20">
          <div className={"InputContainer W_D__F_"}>
            <input
              className={"InputField Rs_Full_Width"}
              placeholder="Key"
              value={headers.key}
              name="key"
              onChange={handleChange}
            />
          </div>
          <div className={"InputContainer W_D__F_"}>
            <input
              className={"InputField Rs_Full_Width"}
              placeholder="Value"
              value={headers.value}
              name="value"
              onChange={handleChange}
            />
          </div>
          <ToolTipWrapper
            PlaceHolder={"Add"}
            Component={<ADD_ICONS />}
            handleFunc={() => {
              if (headers.key && headers.value) {
                setMultipleHeader((pre) => [...pre, headers]);
                setHeader({ key: "", value: "" });
              }
            }}
          />
        </div>
        <div
          className="F_g"
          style={{ flexWrap: "wrap", justifyContent: "flex-start" }}
        >
          {multiple_header?.map((data, index) => {
            return (
              <div key={index} className="_Capsules">
                {`${Custom_Slice_String(data?.key, 15)}: ${Custom_Slice_String(
                  data?.value,
                  15
                )}`}

                <ToolTipWrapper
                  PlaceHolder={"Cancel"}
                  Component={<CROSSICON />}
                  handleFunc={() => {
                    const filteredArray = multiple_header?.filter(
                      (data, nestedIndex) => nestedIndex !== index
                    );

                    setMultipleHeader(filteredArray);
                  }}
                />
              </div>
            );
          })}
        </div>

        <div>
          <MultipleSelectChip
            names={domainOptions}
            title={"Domain"}
            setter={(value) => {
              let gotit = value.map((data) => {
                let filteredArray = TableRowData.filter(
                  (nestedData) => nestedData?.domain === data
                );
                if (filteredArray.length > 0) {
                  return filteredArray[0];
                }
              });

              setMultipleDomain(gotit);
            }}
            internalSetter={setmultipleDomainOptions}
            value={multipleDomainOptions}
          />
        </div>

        <div>
          <CustomCheckbox
            color="primary"
            // checked={slt}
            checked={reschedule}
            onClick={(e) => {
              if (e.target.checked) {
                setreschedule(true);
              } else {
                setreschedule(false);
              }
            }}
          />{" "}
          <span className="VM">Resend failed webhook 5 times</span>
        </div>
        <div className="Mid Rs_Full_Width G_20">
          <button
            className={"Fade_Button"}
            onClick={() => navigate("/webhooks-management")}
          >
            Cancel
          </button>
          <button
            className={
              !btnDis
                ? "inviteBtn Dis Rs_Full_Width"
                : "inviteBtn Rs_Full_Width"
            }
            disabled={!btnDis}
            onClick={() => {
              if (isValidUrl(target)) {
                handleAddEvent();
                setLoading(true);
              } else {
                ERROR("Target should be a valid URL.");
              }
            }}
          >
            ADD
          </button>
        </div>
      </div>
      <div className="__W_H _20P ___FW" style={{ color: "white" }}>
        {<SHOWIT />}
      </div>
    </div>
  );
};

export default WebhookModal;

export const WebhookModal_Update_View = (props) => {
  const [loading, setLoading] = useState("");
  const [name, setName] = useState("");
  const [types, setType] = useState("");
  const [secret, setsecret] = useState("");
  const [domainOptions, setDomainOptions] = useState([]);
  const [headers, setHeader] = useState({ key: "", value: "" });
  const [domain, setDomain] = useState("");
  const [target, setTarget] = useState("");
  const [action, setAction] = useState([]);
  const [TableRowData, setTableRowData] = useState([]);
  const [status, setStatus] = useState("");
  const [_id, setId] = useState("");
  const location = useLocation();
  const [multiple_header, setMultipleHeader] = useState([]);
  const [multipleDomain, setMultipleDomain] = useState([]);
  const [view, setView] = useState(false);
  const navigate = useNavigate();
  const [multipleDomainOptions, setmultipleDomainOptions] = useState([]);
  const [reschedule, setreschedule] = useState(false);
  const { UserId, modalType } = location.state;
  const btnDis =
    Boolean(action) && Boolean(name) && Boolean(secret) && Boolean(types);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  async function getEventData() {
    try {
      const response = await GET_DATA("/get/all/events");
      setTableRowData(response?.data);
      let domain = response?.data?.map((data) => ({
        label: data?.domain,
        value: data?.domain,
      }));
      setDomainOptions(domain);
    } catch (err) {}
  }

  function SHOWIT(obj) {
    return (
      <> {multipleDomain?.map((data, key) => USEIT(data?.payload, key))}</>
    );
  }

  async function handleAddEvent() {
    try {
      setLoading(false);

      const response = await POST_API("update/webhook/data", {
        name,
        target,
        event: multipleDomain,
        types,
        secret,
        headers: multiple_header,
        status,
        reschedule,
        _id,
      });
      SUCCESS("Webhook Updated");
      navigate("/webhooks-management");
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEventData();

    if (modalType === "View") {
      setView(true);
    }

    setmultipleDomainOptions([]);
    UserId?.event.map((data) =>
      setmultipleDomainOptions((pre) => [...pre, data?.domain])
    );
    setName(UserId?.name);
    setreschedule(UserId?.reschedule);
    setStatus(UserId?.status);
    setTarget(UserId?.target);
    setType(UserId?.types);
    setType(UserId?.types);
    setsecret(UserId?.secret);
    setAction(UserId?.event?.action);
    setAction(UserId?.event[0]?.action);
    setDomain(UserId?.event[0]?.domain);
    setMultipleHeader(UserId?.headers);
    setMultipleDomain(UserId?.event);
    setId(UserId?._id);
  }, []);

  return (
    <div className="F_g R_CL">
      <SpinnerLoader state={loading} />

      <div className="Colm GP__20 __W_H ___FW">
        <div className={"InputContainer W_D__F_"}>
          <input
            className={"InputField Rs_Full_Width"}
            placeholder="Name"
            value={name}
            disabled={view}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={"InputContainer W_D__F_"}>
          <input
            className={"InputField Rs_Full_Width"}
            placeholder="Target"
            value={target}
            disabled={view}
            onChange={(e) => {
              setTarget(e.target.value);
            }}
          />
        </div>

        <div className={"InputContainer W_D__F_"}>
          <input
            className={"InputField Rs_Full_Width"}
            placeholder="Secret"
            disabled={view}
            value={secret}
            onChange={(e) => {
              setsecret(e.target.value);
            }}
          />
        </div>
        <div className="W_D__F">
          <CUSTOM_SELECTOR
            name={"Entity"}
            setter={(value) => setType(value)}
            value={types}
            dis={view}
            options={[
              { value: "individual", label: "Individual" },
              { value: "entity", label: "Entity" },
            ]}
          />
        </div>

        <span>Headers</span>
        <div className="Space_Between_Div G_20">
          <div className={"InputContainer W_D__F_"}>
            <input
              className={"InputField Rs_Full_Width"}
              placeholder="Key"
              value={headers.key}
              disabled={view}
              name="key"
              onChange={handleChange}
            />
          </div>
          <div className={"InputContainer W_D__F_"}>
            <input
              className={"InputField Rs_Full_Width"}
              placeholder="Value"
              value={headers.value}
              name="value"
              disabled={view}
              onChange={handleChange}
            />
          </div>
          <ToolTipWrapper
            PlaceHolder={"Add"}
            Component={<ADD_ICONS />}
            handleFunc={() => {
              if (headers.key && headers.value && !view) {
                setMultipleHeader((pre) => [...pre, headers]);
                setHeader({ key: "", value: "" });
              }
            }}
          />
        </div>
        <div
          className="F_g"
          style={{ justifyContent: "flex-start", flexWrap: "wrap" }}
        >
          {multiple_header?.map((data, index) => {
            return (
              <div key={index} className="_Capsules">
                {/* {`${data?.key}: ${data?.value}`} */}
                {`${Custom_Slice_String(data?.key, 15)}: ${Custom_Slice_String(
                  data?.value,
                  15
                )}`}

                <ToolTipWrapper
                  PlaceHolder={"Cancel"}
                  Component={<CROSSICON />}
                  handleFunc={() => {
                    if (!view) {
                      const filteredArray = multiple_header?.filter(
                        (data, nestedIndex) => nestedIndex !== index
                      );

                      setMultipleHeader(filteredArray);
                    }
                  }}
                />
              </div>
            );
          })}
        </div>

        <div>
          <MultipleSelectChip
            names={domainOptions}
            title={"Domain"}
            setter={(value) => {
              let gotit = value.map((data) => {
                let filteredArray = TableRowData.filter(
                  (nestedData) => nestedData?.domain === data
                );
                if (filteredArray.length > 0) {
                  return filteredArray[0];
                }
              });
              setMultipleDomain(gotit);
            }}
            internalSetter={setmultipleDomainOptions}
            value={multipleDomainOptions}
            dis={view}
          />
        </div>

        <div>
          <CustomCheckbox
            color="primary"
            disabled={view}
            checked={reschedule}
            onClick={(e) => {
              if (e.target.checked) {
                setreschedule(true);
              } else {
                setreschedule(false);
              }
            }}
          />{" "}
          <span className="VM">Resend failed webhook 5 times</span>
        </div>
        <div className="Mid Rs_Full_Width G_20">
          <button
            className={"Fade_Button"}
            onClick={() => navigate("/webhooks-management")}
          >
            Cancel
          </button>
          {!view && (
            <button
              className={
                !btnDis
                  ? "inviteBtn Dis Rs_Full_Width"
                  : "inviteBtn Rs_Full_Width"
              }
              disabled={!btnDis}
              onClick={() => {
                if (isValidUrl(target)) {
                  handleAddEvent();
                  setLoading(true);
                } else {
                  ERROR("Target should be a valid URL.");
                }
              }}
            >
              Update
            </button>
          )}
        </div>
      </div>
      <div className="__W_H _20P ___FW">
        <div className="colorWhite " style={{ overflow: "auto" }}>
          {<SHOWIT />}
        </div>
      </div>
    </div>
  );
};

export const Show_Payload_Logs = (props) => {
  const { setOpen, viewData } = props;

  return (
    <div className="Modal_Custom">
      <div className="Space_Between_Div">
        <div>
          <h2>{`View logs`}</h2>
        </div>
        <div onClick={() => setOpen(false)}>
          <CROSSICON />
        </div>
      </div>
      <div
        className="debugCont"
        style={{ background: "#212736", overflow: "auto" }}
      >
        {USEIT(viewData)}
      </div>
    </div>
  );
};
