import React from "react";
import { QUESTIONS } from "../../../Global/Icons";
import {
  GET_COUNTRY_FLAG,
  IsEmptyObject,
  NOTHING,
} from "../../../Global/Constant";
import { ToolTipWrapper } from "../../../Global/ToolTipWraper";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const DPEP = ({
  showsanctions,
  setModalOpen,
  setCurrentData,
  UpdateStatus,
  setLoader,
  analytic,
  isSanctionRan,
}) => {
  function OpenModal(title, data) {
    setModalOpen(true);
    setCurrentData({
      title,
      data,
    });
  }
  return IsEmptyObject(showsanctions) ? (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className="HeaderCenter"
                  style={{
                    background: "rgba(96, 96, 96, 0.1)",
                    color: "rgba(70, 78, 95, 1)",
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  className="HeaderCenter"
                  style={{
                    background: "rgba(96, 96, 96, 0.1)",
                    color: "rgba(70, 78, 95, 1)",
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  className="HeaderCenter"
                  style={{
                    background: "rgba(96, 96, 96, 0.1)",
                    color: "rgba(70, 78, 95, 1)",
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Area of Work
                </TableCell>
                <TableCell
                  className="HeaderCenter"
                  style={{
                    background: "rgba(96, 96, 96, 0.1)",
                    color: "rgba(70, 78, 95, 1)",
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Country
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(showsanctions) &&
                showsanctions?.map((data, index) => {
                  return (
                    <TableRow key={index} className="Space_Between_Div ">
                      <TableCell className=" Center_Div T_A_CE">
                        <span className="T_1">{data?.fullname}</span>
                      </TableCell>
                      <TableCell className=" Center_Div T_A_CE">
                        <span className="T_1">{data?.title}</span>
                      </TableCell>
                      <TableCell className="f_two Center_Div T_A_CE">
                        <span className="T_1">{data?.area_of_work}</span>
                      </TableCell>
                      <TableCell className=" Center_Div T_A_CE">
                        <GET_COUNTRY_FLAG code={data?.country} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "3%",
          }}
        >
          <div className="g_2_0 Center_Div">
            <div className="Center_Div">
              <span className="T_1">Verify Screening</span>
            </div>
            <button
              className="Active_Button"
              onClick={() => {
                UpdateStatus("approved");
                setLoader(true);
              }}
            >
              Accept
            </button>
            <button
              className="Active_Button"
              onClick={() => {
                UpdateStatus("rejected");
                setLoader(true);
              }}
            >
              Reject
            </button>
          </div>
          <div></div>
        </div>
        <div>
          <ToolTipWrapper
            PlaceHolder={"Data"}
            Component={<QUESTIONS />}
            handleFunc={() => {
              OpenModal("Data", analytic);
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      {isSanctionRan && <NOTHING />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "3%",
          }}
        >
          <div className="g_2_0 Center_Div">
            <div className="Center_Div">
              <span className="T_1">Verify Screening</span>
            </div>
            <button
              className="Fade_Button"
              disabled
              onClick={() => {
                UpdateStatus("approved");
                setLoader(true);
              }}
            >
              Accept
            </button>
            <button
              className="Fade_Button"
              disabled
              onClick={() => {
                UpdateStatus("rejected");
                setLoader(true);
              }}
            >
              Reject
            </button>
          </div>
        </div>
        <div>
          <ToolTipWrapper
            PlaceHolder={"Data"}
            Component={<QUESTIONS />}
            handleFunc={() => {
              OpenModal("Data", analytic);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DPEP;
