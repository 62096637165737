import {
  UPDATE_CUSTOMIZER_STATE,
  ACCESS_OPTIONS,
  GET_USER_DATA,
  GET_CREDIT_SCORE,
  GET_PERKS_ACCESS,
  TOGGLE_SHOW_RISK_STORE,
  TOGGLE_WORKFLOW_TUTORIAL,
  TOGGLE_CREATE_INDIVIDUAL_TUTORIAL,
  TOGGLE_VIEW_INDIVIDUAL_TUTORIAL,
  TOGGLE_CREATE_ENTITY_TUTORIAL,
  TOGGLE_VIEW_ENTITY_TUTORIAL,
  TOGGLE_INDIVIDUAL_ONBOARDING_TUTORIAL,
  SET_TUTORIAL_STEP,
  SET_INDIVIDUAL_SCORE,
  SET_ENTITY_SCORE,
  SET_PAGINATION,
  SET_PAGINATION_ENTITY,
} from "../constants";

export const ToggleState = (payload) => ({
  type: UPDATE_CUSTOMIZER_STATE,
  payload,
});
export const SetAccess = (payload) => ({
  type: ACCESS_OPTIONS,
  payload,
});
export const SET_USER_DATA = (payload) => ({
  type: GET_USER_DATA,
  payload,
});
export const SET_CREADIT_SCORE = (payload) => ({
  type: GET_CREDIT_SCORE,
  payload,
});
export const SET_PERK_ACCESS = (payload) => ({
  type: GET_PERKS_ACCESS,
  payload,
});
export const UPDATE_SHOW_RISK = (payload) => ({
  type: TOGGLE_SHOW_RISK_STORE,
  payload,
});
export const toggleWorkFlowTutorial = () => ({
  type: TOGGLE_WORKFLOW_TUTORIAL,
});
export const toggleCreateIndiviualTutorial = () => ({
  type: TOGGLE_CREATE_INDIVIDUAL_TUTORIAL,
});
export const toggleViewIndividualTutorial = () => ({
  type: TOGGLE_VIEW_INDIVIDUAL_TUTORIAL,
});
export const toggleCreateEntityTutorial = () => ({
  type: TOGGLE_CREATE_ENTITY_TUTORIAL,
});
export const toggleViewEntityTutorial = () => ({
  type: TOGGLE_VIEW_ENTITY_TUTORIAL,
});
export const toggleIndividualOnboardingTutorial = () => ({
  type: TOGGLE_INDIVIDUAL_ONBOARDING_TUTORIAL,
});
export const setTutorialStep = (step) => ({
  type: SET_TUTORIAL_STEP,
  payload: step,
});
export const setIndividual_Score = (scores) => ({
  type: SET_INDIVIDUAL_SCORE,
  payload: scores,
});
export const setEntity_Score = (scores) => ({
  type: SET_ENTITY_SCORE,
  payload: scores,
});
export const SET_PAGINATION_UPDATE = (scores) => ({
  type: SET_PAGINATION,
  payload: scores,
});

export const SET_UPDATE_PAGINATION_ENTITY = (scores) => ({
  type: SET_PAGINATION_ENTITY,
  payload: scores,
});
