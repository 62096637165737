import React from "react";
import { getToken } from "../../Api/GetData";
import { useNavigate } from "react-router-dom";

const Secure = () => {
  const navigate = useNavigate();
  let Token = getToken();
  if (!Token) return navigate("/login");
  else return navigate("/");
};

export default Secure;
